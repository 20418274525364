*
{
    margin: 0;
    padding: 0;
}


body
{
    overflow-x: hidden;
    background-color: rgb(24, 24, 24);
}

.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    mix-blend-mode:exclusion;
    opacity: 45%;
}

.main-container {
    height: 100vh;
    width: 100vw;
    display: grid;
    place-content: center;
}


#name-svg path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}

#subtitle-svg path {
    z-index: 2;
    bottom: 20vh;
}

#main {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(20, auto);
}

#main > div {
    background-color: rgb(24, 24, 24);
    height: 100vh;
}

h1 {
    font-size: 5em;
    margin-top: 100vh;
    position: absolute;   
    color: white;
    top: 40%;
    left: 12%;
    opacity: 0;
    z-index: 2;
    font-style: italic;
}

#name-intro {
    font-size: 2em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 112vh;
    position: absolute;  
    color: white;
    top: 40%;
    left: 12%;
    opacity: 0;
    z-index: 2;
    font-style: italic;
}


#Hibutton {
    font-size: 2em;
    margin-top: 130vh;
    position: absolute;  
    left: 12%;
    opacity: 0;
    z-index: 3;
    width: 20vw;
}

#intro {
    display: flex;
    z-index: 2;
    color: white;
    font-size: 1em;
    position: absolute;
    left: 12%;
    margin-top: 45vh;
    opacity: 0;
}